import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import FreeTrialButton from '../components/free-trial-button';

import savingsSrc from '../images/savings.svg';
import supportSrc from '../images/support.svg';
import warningSrc from '../images/warning.svg';
import supportSmallSrc from '../images/support-small.svg';
import splitTestingSrc from '../images/split-testing.svg';

const TSheetsTestimonial = () => {
	return (
		<div className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
		  <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
		    <svg className="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404">
		      <defs>
		        <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
		          <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
		        </pattern>
		      </defs>
		      <rect width="404" height="404" fill="url(#svg-pattern-squares-1)" />
		    </svg>

		    <div className="relative">
		      <blockquote className="mt-8">
		        <div className="max-w-3xl mx-auto text-center text-2xl italic leading-9 font-medium text-gray-900">
		           "I work with construction sector mainly and deal with timesheets weekly. It took me a while to get my head around TSheets but the site guys hated it and the cost is quite a lot and with little support. <br/><br />
					Since then I have implemented TimeKeeper in pretty much every construction company I have gone into to set new systems up and it works brilliantly! <br/ > <br /> 
					I know TSheets is going through change at the minute so if you are looking for alternative, better and cheaper then give them a go!"
		        </div>
		        <footer className="mt-8">
		          <div className="md:flex md:items-center md:justify-center">
		            <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
		              <div className="text-base leading-6 font-medium text-gray-900">Maria Noonan</div>

		              <svg className="hidden md:block mx-1 h-5 w-5 text-highlight-600" fill="currentColor" viewBox="0 0 20 20">
		                <path d="M11 0h3L9 20H6l5-20z" />
		              </svg>

		              <div className="text-base leading-6 font-medium text-gray-500">Bookkeeper, Accounts Keeper</div>
		            </div>
		          </div>
		        </footer>
		      </blockquote>
		    </div>
		  </div>
		</div>
	)
}


const TSheetsAlternative = () => (
  <Layout>
    <SEO 
    	title="Best TSheets/Quickbooks Time UK Alternative"
    	description="TSheets used to be great, now there are better solutions."
    	 />
    <Navbar />
    <div className="bg-gray-50">
		  <div className="relative bg-white overflow-hidden">
			  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
			    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
			      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
			        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
			          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
			            Looking for a <br /> <span className="text-highlight-600">TSheets alternative?</span> <br />
			          </h2>

			          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
			            TimeKeeper is a UK based alternative to TSheets/Quickbooks Time that we think is simpler, friendlier and 50% cheaper.
			          </p>

			          <FreeTrialButton />
			        </div>
			        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
			          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
			            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
			              <img className="w-full" src={splitTestingSrc} alt="TSheets vs TimeKeeper" />
			            </button>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className="py-8 bg-gray-50 overflow-hidden lg:py-24">
			  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
				     <RightFeature 
				    	sectionTitle="TSheets Has Undergone Some Changes" 
				    	title="TSheets has changed" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          TSheets used to be best for small businesses - however since Intuit acquired TSheets a few years ago, it's steadily been getting more and more corporate, so much so, they've 
						          changed their name to Quickbooks Time.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	These changes have sparked some negative reviews on <a className="text-highlight-600" href="https://uk.trustpilot.com/review/www.tsheets.com">Trustpilot</a> about the company. 
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={warningSrc}
				    	imgAlt="TSheets Warning"
				    />
				    <LeftFeature 
				    	sectionTitle="UK Based Support" 
				    	title="No bots, just friendly UK support"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          TSheets are a US based company which can be difficult for companies in other timezones. For time and attendance, it's useful to know you can reach out to a team who can help you through any issues when you encounter them.
						        </FeaturePararaph>
						        <FeaturePararaph>
						           With TimeKeeper you can chat directly to a UK based team who would be more than happy to help.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={supportSrc}
				    	imgAlt="UK Based Support"
				     />
				     <RightFeature 
				    	sectionTitle="Same Features But Much Cheaper" 
				    	title="Stop burning money on TSheets" 
				    	body={
				    		<div>
						        <FeaturePararaph>
						          TSheets used to be much more cost effective however over the past few years have steadily raised their prices by over 100% as 
						          well as charging a base fee.
						        </FeaturePararaph>
						        <FeaturePararaph>
						        	With TimeKeeper we charge a flat £3.50 per employee per month for all the same features and have no base fee. We're over 50% cheaper than TSheets (as of February 2022) allowing you to save thousands overnight.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={savingsSrc}
				    	imgAlt="Save 50% vs TSheets with TimeKeeper"
				    />
				    <LeftFeature 
				    	sectionTitle="Supporting Small Business" 
				    	title="We're a small business just like you"
				    	body={
				    		<div>
					    		<FeaturePararaph>
						          TSheets are part of a huge corporate now and that's great for them. Ultimately their product is a complete success already regardless of the future.
						        </FeaturePararaph>
						        <FeaturePararaph>
						           TimeKeeper is a small UK based company, every £ we earn goes towards making a better product for our users as well as helping us look after our loved ones.
						        </FeaturePararaph>
						    </div>
				    	}
				    	imgSrc={supportSmallSrc}
				    	imgAlt="Support small business"
				     />
				</div>
				<TSheetsTestimonial />
				<FreeTrial part1={(<span>Save <span className="text-highlight-600">thousands today</span> moving from TSheets <br /> to TimeKeeper!</span>)} />
			</div>
		</div>
		<Footer/>
  </Layout>
)

export default TSheetsAlternative;